import { Container } from '@ui/components/Container'
import { LogoWithMark } from '@ui/components/Svg'
import { Button } from '@ui/components/buttons'

export function LandingHeader({
  className,
  isLoggedIn = false,
  trackEvent,
}: {
  className?: string
  isLoggedIn?: boolean
  trackEvent?: (eventName: string, data?: Record<string, unknown>) => void
}) {
  return (
    <header className={className}>
      <Container className="relative flex h-[112px] justify-between">
        <div className="z-10 flex w-full items-center justify-between">
          <span className="sr-only">ATM.com</span>
          <LogoWithMark className="h-12 w-auto" width={138} height={48} />
          <nav className="flex items-center gap-6">
            <Button
              href="/auth/register"
              variant="blueberrySm"
              className="min-w-[152px]"
              {...(trackEvent && {
                onClick: () =>
                  trackEvent('click_header_sign_up', { comp: 'LandingHeader' }),
              })}
            >
              Sign Up
            </Button>
          </nav>
        </div>
      </Container>
    </header>
  )
}
