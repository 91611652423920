import { useSession } from '@web-app/hooks/useSession'
import { useEventTracking } from '@web-app/hooks/useEventTracking'
import { LandingLayout } from '@ui/layout/LandingLayout'

export function WebLandingLayout({
  layoutClassName,
  headerClassName,
  mainClassName,
  footerClassName,
  children,
}: {
  layoutClassName?: string
  headerClassName?: string
  mainClassName?: string
  footerClassName?: string
  children: React.ReactNode
}) {
  const { trackEvent } = useEventTracking()
  const { sessionData } = useSession()
  const user = sessionData?.user
  const isLoggedIn = Boolean(user)

  return (
    <LandingLayout
      layoutClassName={layoutClassName}
      headerClassName={headerClassName}
      mainClassName={mainClassName}
      footerClassName={footerClassName}
      isLoggedIn={isLoggedIn}
      trackEvent={trackEvent}
    >
      {children}
    </LandingLayout>
  )
}
